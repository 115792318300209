import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="icon fa-code"></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>I'm Alberto Cerrone</h1>
        <p>
          A Junior software engineer
        </p>
      </div>
    </div>
    <nav>
      <ul className="buttons-container-landing">
        <li>
          <button
            href="#about-me"
            className="about-me"
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About Me
          </button>
        </li>
        <li>
          <button
            className="my-tools"
            href="#my-tools"
            onClick={() => {
              props.onOpenArticle('tools')
            }}
          >
            Tools
          </button>
        </li>
        <li>
          <button
            className="my-projects"
            href="#my-projects"
            onClick={() => {
              props.onOpenArticle('project')
            }}
          >
            Projects
          </button>
        </li>
        <li>
          <button
            className="my-contacts"
            href="#my-contacts"
            onClick={() => {
              props.onOpenArticle('contacts')
            }}
          >
            Contacts
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
