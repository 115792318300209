import PropTypes from 'prop-types'
import React from 'react'

import MyContacts from './main-components/MyContacts'
import MyProfile from './main-components/MyProfile'
import MyProjects from './main-components/MyProjects'
import Tools from './main-components/Tools'

function Main(props) {


  let close = (
    <div
      className="close"
      role="command"
      onClick={() => {
        props.onCloseArticle()
      }}
    ></div>
  )

  return (
    <div
      ref={props.setWrapperRef}
      id="main"
      style={props.timeout ? { display: 'flex' } : { display: 'none' }}
    >
      <article
        id="about"
        className={`${props.article === 'about' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''
          }`}
        style={{ display: 'none' }}
      >
        <MyProfile />

        {close}
      </article>

      <article
        id="tools"
        className={`${props.article === 'tools' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''
          }`}
        style={{ display: 'none' }}
      >

        <Tools />
        {close}
      </article>

      <article
        id="project"
        className={`${props.article === 'project' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''
          }`}
        style={{ display: 'none' }}
      >
        <MyProjects />
        {close}
      </article>

      <article
        id="contacts"
        className={`${props.article === 'contacts' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''
          }`}
        style={{ display: 'none' }}
      >
        <MyContacts />
        {close}
      </article>
    </div >
  )
}


Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
