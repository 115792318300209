import React from 'react'

import bugbuster from '../../images/bugbuster.webp'
import deverr from '../../images/deverr.webp'
import canieatthis from '../../images/canieatthis.webp'
import minesweeper from '../../images/minesweeper.webp'

function MyProjects() {
  return (
    <div>

      <h2 className="major">My Projects</h2>
      <div style={{ display: 'flex', flexDirection: "column" }}>
        <div className="project">
          <h3>The Bugbuster</h3>
          <img src={bugbuster} alt="" />

          <div className="links">
            <ul className="icons">
              <li>
                <a
                  href="http://github.com/albertocerrone/the-bugbuster"
                  className="icon fa-github"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="label">GitHub</span>
                </a>
              </li>
              <li>
                <a
                  href="https://the-bugbuster.herokuapp.com/"
                  className="icon fa-globe"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="label">Website</span>
                </a>
              </li>
            </ul>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="card">
              <i className="devicon-python-plain frameworks" id="image" ></i>
              <p>Python</p>
            </div>
            <div className="card">
              <i className="devicon-django-line frameworks" id="image"></i>
              <p>Django</p>
            </div>
            <div className="card">
              <i className="devicon-postgresql-plain frameworks" id="image"></i>
              <p>PostgreSQL</p>
            </div>
            <div className="card">
              <i className="devicon-react-original frameworks" id="image"></i>
              <p>React</p>
            </div>

            <div className="card">
              <i className="devicon-materialui-plain frameworks" id="image"></i>
              <p>Material-UI</p>
            </div>
          </div>
          <p>
            The Bugbuster is a bug tracker, thought to be a customized software developed and tailored for a single entity. <br />
              This project has been developed during General Assembly course, with the goal of design a full-stack React app using Python, Django, and PostgreSQL. <br /> It's a group project, built in 7 days. <br /> I'm actively working on it.
          </p>
        </div>
        <div className="project">
          <h3>Deverr</h3>
          <img src={deverr} alt="" />

          <div className="links">
            <ul className="icons">
              <li>
                <a
                  href="http://github.com/albertocerrone/deverr"
                  className="icon fa-github"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="label">GitHub</span>
                </a>
              </li>
              <li>
                <a
                  href="https://deverr.herokuapp.com/"
                  className="icon fa-globe"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="label">Website</span>
                </a>
              </li>
            </ul>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="card">
              <i className="devicon-mongodb-plain framework" id="image" ></i>
              <p>MondoDB</p>
            </div>
            <div className="card">
              <i className="devicon-express-original frameworks" id="image"></i>
              <p>Express</p>
            </div>
            <div className="card">
              <i className="devicon-nodejs-plain frameworks" id="image"></i>
              <p>NodeJS</p>
            </div>
            <div className="card">
              <i className="devicon-react-original frameworks" id="image"></i>
              <p>React</p>
            </div>
          </div>
          <p>
            Deverr allows Developers to connect with companies or people advertsing jobs. Inspired by the Fiverr website, the platform is based on a bidding system where Auctioneers post Jobs and interested developers(Bidders) can ‘bid’ to win the contract.
              <br />
              This project has been developed during General Assembly course, with the goal of design a full-stack MERN app using over 10 day time. <br /> I'm actively working on this project.
          </p>
        </div>
        <div className="project">
          <h3>Can I Eat This</h3>
          <img src={canieatthis} alt="" />

          <div className="links">
            <ul className="icons">
              <li>
                <a
                  href="http://github.com/albertocerrone/can-i-eat-this"
                  className="icon fa-github"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="label">GitHub</span>
                </a>
              </li>
              <li>
                <a
                  href="https://canieatthis.netlify.app/"
                  className="icon fa-globe"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="label">Website</span>
                </a>
              </li>
            </ul>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="card">
              <i className="devicon-javascript-plain frameworks" id="image" ></i>
              <p>JavaScript</p>
            </div>
            <div className="card">
              <i className="devicon-sass-original frameworks" id="image"></i>
              <p>SASS</p>
            </div>
            <div className="card">
              <i className="devicon-react-original frameworks" id="image"></i>
              <p>React</p>
            </div>
          </div>
          <p>
            Can I Eat This is a website where users can select their allergies and dietary restrictions from a list, then input any recipe URL and the website will give back a list of the user's allergens that are contained in the recipe.
            <br />
            This React app is my first pair-coding 48-hour hackathon, using the Spoonacular API.
          </p>
        </div>
        <div className="project">
          <h3>Minesweeper</h3>
          <img src={minesweeper} alt="" />

          <div className="links">
            <ul className="icons">
              <li>
                <a
                  href="http://github.com/albertocerrone/minesweeper"
                  className="icon fa-github"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="label">GitHub</span>
                </a>
              </li>
              <li>
                <a
                  href="https://bit.ly/3qhLWmg"
                  className="icon fa-globe"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="label">Website</span>
                </a>
              </li>
            </ul>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="card">
              <i className="devicon-html5-plain frameworks" id="image"></i>
              <p>HTML</p>
            </div>
            <div className="card">
              <i className="devicon-css3-plain frameworks" id="image"></i>
              <p>CSS</p>
            </div>
            <div className="card">
              <i className="devicon-javascript-plain frameworks" id="image" ></i>
              <p>JavaScript</p>
            </div>
          </div>
          <p>
            This project is the representation of Minesweeper from Windows 95.
            <br />
            This is my very first project after 3 weeks into coding with General Assembly and built in 7 days.
          </p>
        </div>
      </div>
    </div>
  )
}

export default MyProjects
