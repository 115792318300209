import React from 'react'

function MyContacts() {
  return (
    <div>
      <h2 className="major">Contacts</h2>
      <p>Please, feel free to contact me for more details or a copy of my CV</p>
      <ul className="icons">
        <li>
          <a
            href="mailto:cerrone.alberto93@icloud.com"
            className="icon fa-envelope"
            target="_blank"
            rel="noreferrer"
          >
            <span className="label">Email</span>
          </a>
        </li>
        <li>
          <a
            href="http://github.com/albertocerrone"
            className="icon fa-github"
            target="_blank"
            rel="noreferrer"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a
            href="http://www.linkedin.com/in/alberto-cerrone/" className="icon fa-linkedin"
            target="_blank"
            rel="noreferrer"
          >
            <span className="label">LinkedIn</span>
          </a>
        </li>
        <li>
          <a
            href="http://twitter.com/AlbertoCerrone"
            className="icon fa-twitter"
            target="_blank"
            rel="noreferrer"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default MyContacts
