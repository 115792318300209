import React from 'react'

const Footer = (props) => (
  <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
    <p className="copyright">&copy; Alberto Cerrone <br /> built with <a href="https://www.gatsbyjs.org/" target="_blank" rel="noreferrer">Gatsby.js</a> in 4:23h thanks to <a href="https://html5up.net" target="_blank" rel="noreferrer">HTML5 UP</a>.</p>
  </footer>
)


export default Footer
