import React from 'react'
import myprofile from '../../images/myprofile.webp'


function MyProfile() {
  return (
    <div>
      <h2 className="major">About Me</h2>
      <span className="image main" style={{ maxHeight: '300px', margin: '10px auto' }}>
        <img src={myprofile} alt="" />
      </span>
      <p>
        I believe that true self-actualization derives from helping others without expecting anything in return, and where success is a measure of your scope of influence. <br />
          In order to mirror these values, I have been learning to code independently which was reinforced by a coding bootcamp while working in hospitality. <br />
          Now, I am keen to make use of my previously earned people-skills, my never-ending curiosity, and leverage the quick scalability of technology to create a better world, one line of code at a time.

          </p>
      <h2 className="major" style={{ marginLeft: 'auto' }}>My Interests</h2>
      <div className="icons-container" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))' }}>
        <div className="card">
          <i className="icon fa-book-dead large" ></i>
          <p>Reading</p>
        </div>
        <div className="card">
          <i className="icon fa-pizza-slice large" ></i>
          <p>Cooking</p>
        </div>
        <div className="card">
          <i className="icon fa-camera-retro large" ></i>
          <p>Photography</p>
        </div>
        <div className="card">
          <i className="icon fa-microchip large" ></i>
          <p>Tech</p>
        </div>
        <div className="card">
          <i className="icon fa-luggage-cart large" ></i>
          <p>Travel</p>
        </div>
        <div className="card">
          <i className="icon fa-chart-line large" ></i>
          <p>Investments</p>
        </div>
        <div className="card">
          <i className="icon fa-bitcoin large" ></i>
          <p>Crypto</p>
        </div>
        <div className="card">
          <i className="icon fa-viruses large" ></i>
          <p>Bio-Hacking</p>
        </div>
      </div>

      <ul>
        <li><strong>My Favourite Food</strong>: <span>A proper Pizza Margherita with buffalo mozzarella</span></li>
        <li><strong>Most Given Book</strong>: <a
          href="https://toolsoftitans.com/"
          target="_blank"
          rel="noreferrer">Tools of Titans by Tim Ferriss</a></li>
        <li><strong>Currently Reading</strong>: <a
          href="https://www.amazon.co.uk/Robert-Martin-Clean-Code-Collection-ebook/dp/B00666M59G/ref=sr_1_4?adgrpid=54834308618&dchild=1&gclid=Cj0KCQiAst2BBhDJARIsAGo2ldWDNU3kiqJFw_w1gkqw_LEzzfVnG9_DFg4gcnDutnT7fnDZdKwxvfMaAsnOEALw_wcB&hvadid=259122168445&hvdev=c&hvlocint=9041110&hvlocphy=9050704&hvnetw=g&hvqmt=b&hvrand=1163161128426118173&hvtargid=kwd-300183211570&hydadcr=17613_1817768&keywords=robert+c+martin+clean+code&qid=1614256731&sr=8-4&tag=googhydr-21"
          target="_blank"
          rel="noreferrer">The Clean Code Collection by Robert C. Martin</a></li>
        <li><strong>Currently Learning</strong>: <a
          href="https://www.udemy.com/course/advanced-javascript-concepts/"
          target="_blank"
          rel="noreferrer">
          JavaScript: The Advanced Concepts</a></li>
      </ul>
    </div>
  )
}

export default MyProfile
