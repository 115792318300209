import React from 'react';
import { withPrefix } from 'gatsby';

function Video({ src }) {
  return (
    <video
      autoPlay
      muted
      loop
      id="bg"
      src={withPrefix(src)}
    >
      <source src={withPrefix(src)} type="video/mp4" />
            Your device does not support playing 'video/mp4' videos
    </video>
  )
}

export default Video