import React from 'react'

function Tools() {
  return (
    <div>
      <h2 className="major">My Tools</h2>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(130px, 1fr))' }}>
        <div className="card">
          <i className="devicon-html5-plain frameworks" ></i>
          <p>HTML</p>
        </div>
        <div className="card">
          <i className="devicon-css3-plain frameworks" ></i>
          <p>CSS</p>
        </div>
        <div className="card">
          <i className="devicon-javascript-plain frameworks" ></i>
          <p>JavaScript</p>
        </div>
        <div className="card">
          <i className="devicon-react-original frameworks" ></i>
          <p>React</p>
        </div>
        <div className="card">
          <i className="devicon-sass-original frameworks" ></i>
          <p>SASS</p>
        </div>
        <div className="card">
          <i className="devicon-materialui-plain frameworks" ></i>
          <p>Material-UI</p>
        </div>
        <div className="card">
          <i className="devicon-python-plain frameworks" ></i>
          <p>Python</p>
        </div>
        <div className="card">
          <i className="devicon-nodejs-plain frameworks" ></i>
          <p>NodeJS</p>
        </div>
        <div className="card">
          <i className="devicon-express-original frameworks" ></i>
          <p>Express</p>
        </div>
        <div className="card">
          <i className="devicon-django-line frameworks" ></i>
          <p>Django</p>
        </div>
        <div className="card">
          <i className="devicon-mongodb-plain frameworks" ></i>
          <p>MongoDB</p>
        </div>
        <div className="card">
          <i className="devicon-postgresql-plain frameworks" ></i>
          <p>PostgreSQL</p>
        </div>
        <div className="card">
          <i className="devicon-visualstudio-plain frameworks" ></i>
          <p>VSCode</p>
        </div>
        <div className="card">
          <i className="devicon-git-plain frameworks" ></i>
          <p>Git</p>
        </div>
        <div className="card">
          <i className="devicon-github-original frameworks" ></i>
          <p>GitHub</p>
        </div>
        <div className="card">
          <i className="devicon-yarn-plain frameworks" ></i>
          <p>Yarn</p>
        </div>
        <div className="card">
          <i className="devicon-npm-original-wordmark frameworks" ></i>
          <p>Npm</p>
        </div>
        <div className="card">
          <i className="devicon-photoshop-plain frameworks" ></i>
          <p>Photoshop</p>
        </div>
        <div className="card">
          <i className="devicon-heroku-original frameworks" ></i>
          <p>Heroku</p>
        </div>
        <div className="card">
          <i className="devicon-bash-plain frameworks" ></i>
          <p>Terminal</p>
        </div>
      </div>
    </div>
  )
}

export default Tools
